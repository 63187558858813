<template>
  <div class="container">
    <div class="pa-20">
      <div class="flex f-center f-between">
        <img class="logo" src="~@/assets/img/logo.png" />
        <div class="flex f-center back-box" @click="$router.replace('/')">
          <img class="back" src="~@/assets/img/back.png" />
          <span>返回</span>
        </div>
      </div>

      <div class="content flex f-center flex-direction f-jcenter">
        <img class="head" src="~@/assets/img/head.png" />
        <div class="font-bold t-center fon-18 ma-tb-30">
          <div>访客姓名：{{ info.name }}</div>
          <div class="ma-tb-10">访客证号码：{{ info.cardNo }}</div>
          <div>访客类型：个人预约</div>
        </div>
        <div class="width60">
          <van-button
            type="primary"
            class="width100"
            color="#003AA9"
            @click="dialogVisibleGiveBack=true"
            >确认离场</van-button
          >
          <!-- 来访类型(00=客户访问,10=施工,20=面试,31=供应商访问-普通访问,32=供应商访问-提货/送货和提货,33=供应商访问-送货) -->
          <!-- 新版 来访类型(00=客户访问,【30=客检】，10=施工/作业,20=面试,【40=供应商访问-普通访问】,【41=供应商访问-提货/送货和提货】,【42=供应商访问-送货】，【43=短驳车】，55 关联公司访问) -->
          <div class="ma-t-20">
            <van-button
              type="primary"
              class="width100"
              color="#f59a23"
              v-if="info.visitType == '41' || info.visitType == '42'"
              @click="showDialog()"
              >填写送/提货信息</van-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 离场归还访客证、停车证弹框 -->
    <el-dialog v-model="dialogVisibleGiveBack" title="提示" :show-close="false" :close-on-click-modal="false">
      <div class="fon-18">
        <div>
          <div>是否归还 <span class="font-bold">访客证？</span></div>
          <div class="ma-t-10">
            <el-radio v-model="formGiveBack.a" label="未归还" border>未归还</el-radio>
            <el-radio v-model="formGiveBack.a" label="归还"  border>归还</el-radio>
          </div>
        </div>
        <div class="ma-t-20" v-if="info.parkingNoList && info.parkingNoList.length>0">
          <div>是否归还 <span class="font-bold">访客停车证？</span></div>
          <div class="ma-t-10">
            <el-radio v-model="formGiveBack.b" label="未归还" border>未归还</el-radio>
            <el-radio v-model="formGiveBack.b" label="归还"  border>归还</el-radio>
          </div>
        </div>
        <div class="flex f-around ma-t-20">
          <van-button  class="width50" plain @click="closeGiveBack()">取消</van-button>
          <van-button color="#003AA9" type="primary" class="width50" @click="submit">确认</van-button>
        </div>
      </div>
    </el-dialog>

    <!-- 填写离场 提货、送货信息弹框 -->
    <form-dialog ref="formDialog" @confirm="formDialogConfirm"></form-dialog>
  </div>
</template>

<script>
let _this;
import dialog from "./components/dialog.vue";
export default {
  components: {
    formDialog: dialog,
  },
  data() {
    return {
      info: "",
      cardNo: "",
      list: [],
      code: "", //预约code
      cardType:"",
      supplyInfo: {}, //离厂送提货信息
      dialogVisibleGiveBack: false,
      formGiveBack: {
        a:'未归还',
        b:'未归还',
      },
    };
  },
  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    next();
  },
  created() {
    _this = this;
    _this.cardNo = _this.$route.query.cardNo;
    _this.code = _this.$route.query.code;
    _this.cardType = _this.$route.query.cardType;
    _this.loadData();
  },
  watch: {
    $route(to, from) {
      //  window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  methods: {
    // 加载数据
    loadData() {
      _this
        .$request({
          url: "/security/query",
          method: "GET",
          params: {
            cardNo: _this.cardNo,
            cardType:_this.cardType,
            code:_this.code,
            queryType: 1,
          },
        })
        .then((res) => {
          _this.info = res;
          _this.list.push(_this.info.id);
          _this.supplyInfo = {
            securityId: _this.info.securityId,
            weightType: _this.info.weightType,
            weightIn: _this.info.weightIn,
            weightOut: _this.info.weightOut,
            palletNum: _this.info.palletNum,
          };
        });
    },

    // 离场
    submit() {
      //数据验证，来访类型为送货/提货，每一次离场都必须【填写送/提货信息】
      //41=供应商访问-提货/送货和提货,42=供应商访问-送货
      
      if (_this.info.visitType == "41") {
        let data = {
          securityId: _this.supplyInfo.securityId,
          weightType: _this.supplyInfo.weightType,
          weightIn: _this.supplyInfo.weightIn,
          weightOut: _this.supplyInfo.weightOut,
          palletNum: _this.supplyInfo.palletNum,
        };
        if (this.$validator.isEmpty(data.securityId)) {
          return this.$message("请填写送/提货信息");
        }
        if (data.weightType !== 0 && data.weightType !== 1) {
          return this.$message("请填写送/提货信息");
        }
        if (this.$validator.isEmpty(data.weightIn) && data.weightType!==1) {
          return this.$message("请填写送/提货信息");
        }
        if (this.$validator.isEmpty(data.weightOut) && data.weightType!==1) {
          return this.$message("请填写送/提货信息");
        }
        if (this.$validator.isEmpty(data.palletNum)) {
          return this.$message("请填写送/提货信息");
        }
      }else if(_this.info.visitType == "42"){
        if (this.$validator.isEmpty(_this.supplyInfo.securityId)) {
          return this.$message("请填写送/提货信息");
        }
      }

      _this
        .$request({
          url: "/security/out",
          method: "POST",
          data: {
            id: _this.info.id,
            entranceId: _this.$store.state.entranceId,
            list: _this.list,
            supplyInfo: _this.supplyInfo,
            cardNoList:_this.getCardNoList(),
            parkingNoList:_this.getParkingNoList()
          },
        })
        .then(() => {
          _this.$router.replace("/enter/success");
        });
    },

    //显示弹框，填写离场的信息
    showDialog() {
      _this.$refs.formDialog.initData(_this.supplyInfo);

      //41=供应商访问-提货/送货和提货,42=供应商访问-送货
      if (_this.info.visitType == "42") {
        _this.$refs.formDialog.showDialog("1");
      } else if (_this.info.visitType == "41") {
        _this.$refs.formDialog.showDialog("2");
      }
    },
    //离场弹框信息填写完
    formDialogConfirm(data) {
      _this.supplyInfo = data;
      _this.$refs.formDialog.hideDialog();
    },
    //隐藏弹框
    closeGiveBack(){
      _this.dialogVisibleGiveBack=false;
      _this.formGiveBack= {
        a:'未归还',
        b:'未归还'
      }
    },
    //获取归还的访客证号码
    getCardNoList(){
      if(_this.formGiveBack.a=='未归还'){
        return []
      }else if(_this.formGiveBack.a=='归还'){
        return [_this.info.cardNo]
      }
    },
    //获取归还的停车证证号码
    getParkingNoList(){
      if(_this.formGiveBack.b=='未归还'){
        return []
      }else if(_this.formGiveBack.b=='归还'){
        return _this.info.parkingNoList
      }
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 476px;
  height: 404px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .head {
    width: 120px;
  }
 
}
.c-red{
    color: red;
  }
</style>