<template>
    <!-- 离场时的弹框 -->
    <div>
        <el-dialog v-model="dialogVisible1" title="">
            <div>

                <div v-if="type == '2'">
                    <div class="flex f-center">
                        <div class="width30 pa-r-20"></div>
                        <div class="width70">
                            <el-radio-group v-model="weightType" class="">
                                <el-radio :label="0" size="large">正常过磅</el-radio>
                                <el-radio :label="1" size="large">超长无法过磅</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="flex f-center ma-t-20">
                        <div class="width30 t-right pa-r-20">过磅重量（进）：</div>
                        <div class="width70"><el-input type="number" min="0" v-model="weightIn" placeholder="请输入内容" /></div>
                    </div>
                    <div class="flex f-center ma-t-20">
                        <div class="width30 t-right pa-r-20">过磅重量（出）：</div>
                        <div class="width70"><el-input  type="number"  min="0"  v-model="weightOut" placeholder="请输入内容" /></div>
                    </div>
                    <div class="flex f-center ma-t-20">
                        <div class="width30 t-right pa-r-20">托盘数：</div>
                        <div class="width70"><el-input  type="number"  min="0"  v-model="palletNum" placeholder="请输入内容" /></div>
                    </div>
                </div>
                <div class="flex f-center ma-t-20">
                    <div class="width30 t-right pa-r-20"> 保安姓名：</div>
                    <div class="width70">
                        <el-select v-model="securityId" placeholder="请选择">
                            <el-option v-for="item in securityList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    </div>
                <div class="t-center ma-t-20">
                    <van-button type="default"  @click="confirm()" class="width100" color="#003AA9">
                        确认
                    </van-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: '',//弹框类型 1 送货，2送货、提货
            dialogVisible1: false,
            securityList: [],//保安列表
            securityId: '',//送提货保安id
            weightType: '',//过磅类型(0=正常过磅,1=超长无法过磅)
            weightIn: '',//过磅重量(进)
            weightOut: '',//过磅重量(出)
            palletNum: '',//托盘数
        }
    },
    methods: {
        //数据初始化
        initData(data){
           this.securityId= data.securityId//送提货保安id
           this.weightType= data.weightType//过磅类型(0=正常过磅,1=超长无法过磅)
           this.weightIn= data.weightIn//过磅重量(进)
           this.weightOut= data.weightOut//过磅重量(出)
           this.palletNum= data.palletNum //托盘数
        },
        //显示弹框
        showDialog(key) {
            this.type = key
            this.loadDataSecurityList()
            this.dialogVisible1 = true
        },
        //隐藏弹框
        hideDialog(){
            this.dialogVisible1=false
        },
        //获取保安列表
        loadDataSecurityList() {
            this.$request({
                url: '/security/out/user',
                method: 'GET',
            }).then((res) => {
                this.securityList = res
            });
        },
        //确认
        confirm() {
            console.log(this.type)
            let data = {}
            if (this.$validator.isEmpty(this.securityId)) {
                    return this.$message('请选择人员');
            }
            if (this.type == 1) {
                data = {
                    securityId: this.securityId
                }
            } else if (this.type == 2) {
                data = {
                    securityId: this.securityId,
                    weightType: this.weightType,
                    weightIn: this.weightIn,
                    weightOut: this.weightOut,
                    palletNum: this.palletNum,
                }
                console.log(data)
                if (data.weightType!==0 && data.weightType!==1) {
                    return this.$message('请选择过磅类型');
                }
                if (this.$validator.isEmpty(data.weightIn) && data.weightType!==1) {
                    return this.$message('请输入过磅重量(进)');
                }
                if (this.$validator.isEmpty(data.weightOut) && data.weightType!==1) {
                    return this.$message('请输入过磅重量(出)');
                }
                if (this.$validator.isEmpty(data.palletNum)) {
                    return this.$message('请输入托盘数');
                }
            }
            this.$emit('confirm', data)
        }
    }
}
</script>

<style>

</style>